@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap");
html {
  font-size: 16px; /* Esto hace que 1rem = 16px */
}
div,
span,
h2,
h3,
h4 {
  font-family: "Montserrat" !important;
}
a:focus {
  outline: none;
  border: none;  /* Agrega esto para asegurarte de que no hay bordes aplicados */
}
a:active {
  outline: none;
  border: none;
}
.font {
  font-family: "Roboto", "DM Sans", "Helvetica Neue", sans-serif;
}
.gCuogY .blockbuilder-branding {
  display: none !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 300px;
  outline: none;
  padding: 6px 16px 6px 60px !important;
  transition:
    box-shadow ease 0.25s,
    border-color ease 0.25s;
  color: #495057;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Language: css */

.bg-login-color {
  background-color: #fcf9f1;
}

.ChromelessStatusBar {
  display: none !important;
}

div[aria-label="Pop-out"] {
  display: none;
}

div[aria-label="toolbar"] {
  width: 52px;
}
